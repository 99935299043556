import {Controller} from "@hotwired/stimulus";
import {TaggedLogger} from "@nextlot/core/utilities";

const _logger = TaggedLogger.get('FormSubmissionController');

export default class FormDisableSubmitButtonController extends Controller {

  connect() {
      this.element.addEventListener('submit', this.onSubmitForm)
  }

  disconnect() {
    this.element.removeEventListener('submit', this.onSubmitForm);
  }


  onSubmitForm = () => {
    // If/when using Turbo Drive navigation in the future, disabling the submit button logic can be removed
    // https://turbo.hotwired.dev/handbook/drive#form-submissions

    const submitBtn = this.element.querySelector('button[type="submit"]') as HTMLButtonElement
    submitBtn.disabled = true;
  }
}
